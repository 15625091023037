import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfigSignitiva = {
  apiKey: process.env.REACT_APP_SIGNITIVA_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_SIGNITIVA_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_SIGNITIVA_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_SIGNITIVA_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_SIGNITIVA_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_SIGNITIVA_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_SIGNITIVA_FIREBASE_MEASUREMENT_ID,
};

const appSignitiva = initializeApp(firebaseConfigSignitiva, "signitiva");
export const dbSignitiva = getFirestore(appSignitiva);
export const analyticsSignitiva = getAnalytics(appSignitiva);