import "./Home.css";
import React, { useState, useEffect, Suspense } from "react";
import logo from "../../assets/images/logo.webp";
import uipath from "../../assets/images/uipath.webp";
import Benefits_Icon_1 from "../../assets/images/Benefits-Icon-1.webp";
import Benefits_Icon_2 from "../../assets/images/Benefits-Icon-2.webp";
import Benefits_Icon_3 from "../../assets/images/Benefits-Icon-3.webp";
import Benefits_Icon_4 from "../../assets/images/Benefits-Icon-4.webp";
import Benefits_Icon_5 from "../../assets/images/Benefits-Icon-5.webp";
import facebook from "../../assets/images/facebook.webp";
import instagram from "../../assets/images/instagram.webp";
import youtube from "../../assets/images/youtube.webp";
import { LazyContact, LazyVideoDesk, LazyVideoMovil } from '../../components/lazy/Lazy';
import { logEvents } from "../../services/f_analytics";

export default function Home () {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <header className="header">
        <div className="grid mx-0">
          <div className="col-12 lg:col-5">
            <div className="containerLogo">
              <picture>
                <source media="(min-width: 600px)" srcSet={logo} />
                <img src={logo} alt="Logo" />
              </picture>
            </div>
            <h1>Potencie su negocio con</h1>
            <div className="containerImage">
              <picture>
                <source media="(min-width: 600px)" srcSet={uipath} />
                <img src={uipath} alt="UiPath" />
              </picture>
            </div>
            <p className="text1">
              Conozca cómo el RPA de UiPath hace que las empresas sean más
              productivas y eficientes, creando una mejor experiencia con sus
              colaboradores.
            </p>
            <div className="center">
              <input
                type="button"
                value="Ver brochure"
                onClick={() => {
                  logEvents("brochure", "view");
                  window.open(
                    "https://firebasestorage.googleapis.com/v0/b/uipathperu-2e055.appspot.com/o/Brochure-UI-Path.pdf?alt=media&token=6cebe0c3-1afd-4aac-9ba6-d8895ea1019c",
                    "_blank"
                  );
                }}
                className="buttonRed"
              />
            </div>
            <div className="center">
              <input
                type="button"
                value="Solicite su prueba de concepto (PoC)"
                onClick={() => {
                  logEvents("POC", "meet");
                  window.open(
                    "https://meetings.hubspot.com/esaldarriaga",
                    "_blank"
                  );
                }}
                className="buttonBlue"
              />
            </div>
          </div>
          <div className="col-12 lg:col-2"></div>
          {
            windowSize.width > 768 &&
            <div className="col-12 lg:col-5 dhidden borderForm">
              <div className="center">
                <h2 className="titleBlue">
                  DESCUBRA TODO EL<br></br> POTENCIAL DEL RPA
                </h2>
              </div>
              <p className="text2 center">Envíenos sus datos para contactarlo</p>
              <div className="mt-4">
                <Suspense>
                  <LazyContact />
                </Suspense>
              </div>
            </div>
          }
        </div>
      </header>
      <main>
        {
          windowSize.width <= 768 &&
          <div className="grid mx-0 mt-4 mhidden">
            <div className="col-12">
              <div className="center">
                <h2 className="titleBlue">
                  DESCUBRA TODO EL<br></br> POTENCIAL DEL RPA
                </h2>
              </div>
              <p className="text2 center">Envíenos sus datos para contactarlo</p>
              <div className="mt-4">
                <Suspense>
                  <LazyContact />
                </Suspense>
              </div>
            </div>
          </div>
        }
        <div className="section1">
          <div className="grid mx-0">
            <div className="col-12 lg:col-2"></div>
            <div className="col-12 lg:col-4 sinergia">
              <h3 className="titleBlue">
                La sinergia perfecta<br className="hiddenSmall"></br> entre
                usuarios y robots
              </h3>
            </div>
            <div className="col-12 lg:col-4">
              <p className="text2 justify">
                El RPA de UiPath, interactúa con sus sistemas y aplicaciones como
                lo hace cualquier usuario, completando procesos por sí mismo de
                manera eficiente, rápida y segura. Así el robot se encargará de
                las tareas repetitivas y rutinarias, para que los trabajadores
                realicen actividades de mayor valor para la empresa.
              </p>
            </div>
            <div className="col-12 lg:col-2"></div>
          </div>
        </div>
        <div className="section2">
          <h4 className="benefitsTitle">BENEFICIOS PARA TU NEGOCIO</h4>
          <div className="grid-container">
            <div className="grid-item">
              <div className="itemImage">
                <picture>
                  <source media="(min-width: 600px)" srcSet={Benefits_Icon_1} />
                  <img src={Benefits_Icon_1} alt="Benefits_Icon_1" />
                </picture>
              </div>
              <p className="itemText">Rápido logro de resultados</p>
            </div>

            <div className="grid-item">
              <div className="itemImage">
                <picture>
                  <source media="(min-width: 600px)" srcSet={Benefits_Icon_2} />
                  <img src={Benefits_Icon_2} alt="Benefits_Icon_2" />
                </picture>
              </div>
              <p className="itemText">Inversión inicialmínima</p>
            </div>

            <div className="grid-item">
              <div className="itemImage">
                <picture>
                  <source media="(min-width: 600px)" srcSet={Benefits_Icon_3} />
                  <img src={Benefits_Icon_3} alt="Benefits_Icon_3" />
                </picture>
              </div>
              <p className="itemText">
                Sin interrupción de los sistemas subyacentes
              </p>
            </div>

            <div className="grid-item">
              <div className="itemImage">
                <picture>
                  <source media="(min-width: 600px)" srcSet={Benefits_Icon_4} />
                  <img src={Benefits_Icon_4} alt="Benefits_Icon_4" />
                </picture>
              </div>
              <p className="itemText">
                Guiado por el negocio y con el respaldo de TI
              </p>
            </div>

            <div className="grid-item">
              <div className="itemImage">
                <picture>
                  <source media="(min-width: 600px)" srcSet={Benefits_Icon_5} />
                  <img src={Benefits_Icon_5} alt="Benefits_Icon_5" />
                </picture>
              </div>
              <p className="itemText">
                Altamente escalable y fácil de adaptar a un entorno empresarial
                cambiante
              </p>
            </div>
          </div>
        </div>
        {
          windowSize.width > 768 ?
          <Suspense>
            <LazyVideoDesk />
          </Suspense> :
          <Suspense>
            <LazyVideoMovil />
          </Suspense>
        }
        <div className="section4">
          <div className="grid mx-0">
            <div className="col-12 md:col-6">
              <h5 className="titleSourIT">
                Sobre Source IT <br className="mhidden"></br> Consulting
              </h5>
              <hr className="hrSourIT"></hr>
              <p className=" textSourIT">
                Es una compañía dedicada a la consultoría en tecnologías de la
                información, fundada en el 2009. Cuenta con más de 10 años de
                sólida trayectoria implementando soluciones empresariales, basadas
                en diferentes aspectos de la ingeniería de software.<br></br>{" "}
                Tiene un portfolio de servicios que cubre: Inteligencia de
                Negocios, Mejora de Procesos y RPA (Automatización de Procesos
                Robóticos), Desarrollo de Aplicaciones y Outsourcing, Oracle
                E-Business y People Soft.
              </p>
            </div>
            <div className="col-12 md:col-1"></div>
            <div className="col-12 md:col-5">
              <div className="textNumber">
                <span className="textNumberBig">+80</span> clientes.
              </div>
              <div className="textNumber">
                <span className="textNumberBig">+10</span> años de experiencia.
              </div>
              <div className="textNumber">
                Presencia internacional en <br></br>
                <span className="textNumberBig">9 países.</span>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer>
        <div className="footerLogo">
          <picture>
            <source media="(min-width: 600px)" srcSet={logo} />
            <img src={logo} alt="Logo" />
          </picture>
          <p className="mt-4">
            Av. Aramburú 878, Surquillo, Lima – Perú <br></br> Tel.: +51 329
            7129
          </p>
          <a href="mailto:contacto@sitconsulting.com.pe" target="_blank" class="w-min m-auto my-2 py-2 px-6 border-2 rounded-3xl font-semibold text-white flex items-center justify-center gap-4" style={{ backgroundColor: "#FFBE00", borderRadius: "24px" }}>Email</a>
          <div className="socialMedia">
            <a
              href="https://www.facebook.com/sourceitconsulting"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="Facebook"
              onClick={() => logEvents("Social", "Facebook")}
            >
              {/* <span className="ocultarse">Facebook</span> */}
              <picture>
                <source media="(min-width: 600px)" srcSet={facebook} />
                <img src={facebook} alt="Facebook" />
              </picture>
            </a>
            <a
              href="https://www.linkedin.com/company/source-it-consulting/"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="Instagram"
              onClick={() => logEvents("Social", "Instagram")}
            >
              <picture>
                <source media="(min-width: 600px)" srcSet={instagram} />
                <img src={instagram} alt="Instagram" />
              </picture>
            </a>
            <a
              href="https://www.youtube.com/channel/UCIornqES2IVkVnzcETJhYRw"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="Youtube"
              onClick={() => logEvents("Social", "Youtube")}
            >
              <picture>
                <source media="(min-width: 600px)" srcSet={youtube} />
                <img src={youtube} alt="Youtube" />
              </picture>
            </a>
          </div>
          <p className="my-2 ">Todos los derechos reservados ©</p>
        </div>
      </footer>
    </>
  );
};
